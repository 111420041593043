import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ImportContactTable from "./ImportContactTable";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";
import {
  Button as MDBButton,
  Modal,
  ModalBody,
  ModalFooter,
  MDBInput,
} from "mdbreact";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Loader from "../../../common/Loader";
import { selectForImportContact } from "../../../config";
import { chunk } from "../../../services/Utils";
import moment from "moment";

export default function ImportContactTableMain({
  navigate,
  typePage,
  setTypePage,
  delegataire,
  userId,
  startDetectImport,
}) {
  const [rows, setRows] = useState([]);
  const [lines, setLines] = useState([]);
  const [linesToShow, setLinesToShow] = useState([]);
  const [firstLine, setFirstLine] = useState([]);
  const [data, setData] = useState([]);
  const [disableContactsOption, setDisableContactsOption] = useState(false);
  const [reportRecipient, setReportRecipient] = useState("");
  const [modal, setModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [numbersRowsToShow, setNumbersRowsToShow] = useState(5);
  const [validate, setValidate] = useState({ lastname: false, mail: false });
  const [selects, setSelects] = useState(selectForImportContact);

  useEffect(() => {
    filterFirstLines();
  }, [rows, firstLine, data]);
  useEffect(() => {
    getNumbersOfRows(lines);
  }, [numbersRowsToShow]);
  useEffect(() => {
    const user = localStorage.getItem("user");
    const { email } = user ? JSON.parse(user) : null;
    if (email) {
      setReportRecipient(email);
    }
  }, []);
  const filterFirstLines = () => {
    if (rows.length > 0) {
      const first = rows.filter((res, index) => index !== 0);
      setLines(first);
      if (first.length > 0) {
        getNumbersOfRows(first);
      }
    }
  };
  const getNumbersOfRows = (array) => {
    if (array.length > 0) {
      setLinesToShow(
        array.filter((res, index) => numbersRowsToShow - 1 >= index)
      );
    }
  };
  const navigation = (e, type) => {
    switch (type) {
      case "previous":
        if (typePage.subPage === "config") {
          setValidate({ lastname: false, mail: false });
          selects.map((select) => {
            select.disabled = false;
            return select;
          });
        }
        navigate(e, {
          page: typePage.subPage === "config" ? "import" : "default",
          subPage: "",
        });
        break;
      case "after":
        if (typePage.subPage === "config") {
          if (checkData()) {
            try {
              toggle();
            } catch (e) {
              toast.error("Erreur lors de l'import des contacts", {
                autoClose: 3000,
              });
            }
          }
        } else if (rows.length && firstLine.length) {
          navigate(e, {
            page: "import",
            subPage: typePage.page === "import" ? "config" : "",
          });
        }
        break;
      default:
        break;
    }
  };
  const clean = () => {
    setRows([]);
    setLines([]);
    setFirstLine([]);
    setData([]);
    setDisableContactsOption(false);
    setValidate({ lastname: false, mail: false });
    selects.map((select) => {
      select.disabled = false;
      return select;
    });
  };
  const checkData = () => {
    const countRow = lines.length;
    const countDataImportLength = data.length;
    let columnDataMissing = false;
    if (!validate.lastname || !validate.mail) {
      if (!validate.mail && !validate.lastname) {
        toast.error("Mail et Lastname n'ont pas été renseignés");
      } else if (!validate.mail) {
        toast.error("Mail n'a pas été renseigné");
      } else if (!validate.lastname) {
        toast.error("Lastname n'a pas été renseigné");
      }
      return false;
    }
    if (countRow !== countDataImportLength) {
      columnDataMissing = true;
    }
    if (columnDataMissing) {
      toast.error("Une colonne n'a pas été selectionné");
      return false;
    }
    return true;
  };

  const importContacts = (e) => {
    e.preventDefault();
    setUploading(true);
    const chunkData = chunk(data, 50000);
    if (chunkData.length > 0) {
      const total = chunkData.length;
      try {
        if (delegataire && !userId) {
          toast.error("Erreur lors de l'import des contacts");
          setUploading(false);
          return;
        } else if (delegataire && userId) {
          chunkData.forEach((data, number) => {
            const obj = {
              data_import: data,
              disableContactsOption,
              report_recipient: reportRecipient,
              total,
              number: number + 1,
              created_by_delegataire: true,
              user_id: userId,
              date_import: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            ApiService.request(obj, "contacts/import/v2", "post", false);
          });
        } else {
          chunkData.forEach((data, number) => {
            const obj = {
              data_import: data,
              disableContactsOption,
              report_recipient: reportRecipient,
              total,
              number: number + 1,
              date_import: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            ApiService.request(obj, "contacts/import/v2", "post", false);
          });
        }
        if (delegataire) {
          toast.warn(
            "Import des contacts en cours... pensez à surveiller vos emails"
          );
        } else {
          toast.success(
            "Import des contacts en cours... pensez à surveiller vos emails"
          );
        }
        if (startDetectImport) {
          startDetectImport(true);
        }
        setTimeout(() => {
          setUploading(false);
          clean();
          navigate(e, { page: "default", subPage: "" });
        }, 2000);
      } catch (e) {
        console.log(e);
        toast.error("Erreur lors de l'import des contacts");
        setUploading(false);
      }
    } else {
      //console.log(e, "la")
      toast.error("Erreur lors de l'import des contacts");
      setUploading(false);
    }
  };
  const toggle = (e) => {
    if (e) e.preventDefault();
    setModal(!modal);
  };
  const returnModal = () => {
    return (
      <Modal isOpen={modal} size="lg" toggle={(e) => toggle(e)}>
        <form className="form" onSubmit={(e) => importContacts(e)}>
          <ModalBody>
            {uploading ? (
              <div className="">
                <div className="flex justify-center">
                  Votre import est cours, vous serez averti par email lorsque ce
                  dernier sera terminé.
                </div>
                <div>
                  <Loader />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col">
                  <strong>Imports de contacts</strong>
                  <br />
                  <p>
                    Les contacts existants seront mis à jour (écrasement des
                    données existantes)
                  </p>
                  <br />
                  <div className="form-row light-grey">
                    <div className="form-group col-md-12">
                      <label>Email de réception du rapport</label>
                      <input
                        type="email"
                        name="reportRecipient"
                        className="form-control"
                        value={reportRecipient}
                        onChange={(e) => setReportRecipient(e.target.value)}
                      />
                    </div>
                    {!delegataire && (
                      <div className="form-group">
                        <MDBInput
                          onClick={(e) =>
                            setDisableContactsOption(!disableContactsOption)
                          }
                          id="1"
                          label={
                            "Désactiver les contacts non présents dans le fichier d'import"
                          }
                          filled
                          checked={disableContactsOption}
                          type="checkbox"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <MDBButton
              type="submit"
              className="btn pink darken-1"
              disabled={!reportRecipient}
            >
              Valider
            </MDBButton>
            <button className="btn cancel-button" onClick={(e) => toggle(e)}>
              Fermer
            </button>
          </ModalFooter>
        </form>
      </Modal>
    );
  };
  return (
    <>
      <div className="main-content">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className={"stats-and-actions"}>
              <div className="row">
                <div className={"col-6"}></div>
                <div className={"col-6"}></div>
              </div>
            </div>

            <div
              className={"filters"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className="flex flex-row">
                <div className="moveComponentLeft moveDiv">
                  <div style={{ cursor: "pointer" }}>
                    <Button
                      variant={"contained"}
                      className="moveButton"
                      onClick={(e) => navigation(e, "previous")}
                    >
                      <ArrowCircleLeftIcon />
                    </Button>
                  </div>
                </div>
                <div className="pushRight moveComponentRight moveDiv">
                  <div style={{ cursor: "pointer" }}>
                    <Button
                      variant={"contained"}
                      className="moveButton"
                      onClick={(e) => navigation(e, "after")}
                    >
                      <ArrowCircleRightIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className={"table-container"}>
              {modal && returnModal()}
              <ImportContactTable
                rows={rows}
                setRows={setRows}
                linesToShow={linesToShow}
                lines={lines}
                setLines={setLines}
                firstLine={firstLine}
                setFirstLine={setFirstLine}
                typePage={typePage}
                setTypePage={setTypePage}
                data={data}
                setData={setData}
                numbersRowsToShow={numbersRowsToShow}
                setNumbersRowsToShow={setNumbersRowsToShow}
                validate={validate}
                setValidate={setValidate}
                selects={selects}
                setSelects={setSelects}
                delegataire={delegataire}
              />
            </div>
            {typePage.page === "import" && typePage.subPage === "config" ? (
              <div
                className="flex flex-row"
                style={{
                  listStyleType: "none",
                  marginBottom: "270px",
                  marginTop: 20,
                }}
              >
                <div className="moveComponentLeft moveDiv">
                  <div style={{ cursor: "pointer" }}>
                    <Button
                      variant={"contained"}
                      className="moveButton"
                      onClick={(e) => navigation(e, "previous")}
                    >
                      <ArrowCircleLeftIcon />
                    </Button>
                  </div>
                </div>
                <div className="pushRight moveComponentRight moveDiv">
                  <div style={{ cursor: "pointer" }}>
                    <Button
                      variant={"contained"}
                      className="moveButton"
                      onClick={(e) => navigation(e, "after")}
                    >
                      <ArrowCircleRightIcon />
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
